@import "https://fonts.googleapis.com/css2?family=Do+Hyeon&family=Mouse+Memoirs&family=Noto+Sans+SC:wght@300;400;700&family=Noto+Serif+SC:wght@300;400;600&family=Yanone+Kaffeesatz:wght@300;400;600&display=swap";

body .introjs-tooltipReferenceLayer * {
  font-family: sl_pixlregular;
}

body .introjs-overlay {
  z-index: 999999;
  -o-transition: all .3s ease-out;
  background: #fff;
  transition: all .3s ease-out;
  position: absolute;
}

body .introjs-fixParent {
  z-index: auto !important;
  opacity: 1 !important;
}

body .introjs-showElement, body tr.introjs-showElement > td, body tr.introjs-showElement > th {
  z-index: 9999999 !important;
}

body .introjs-relativePosition, body tr.introjs-showElement > td, body tr.introjs-showElement > th {
  position: relative;
}

body .introjs-helperLayer {
  z-index: 9999998;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  position: absolute;
}

body .introjs-helperNumberLayer {
  color: #fff;
  text-align: center;
  width: 20px;
  height: 20px;
  background: #003948;
  padding: 10px;
  font-family: sl_pixlregular;
  font-size: 13px;
  font-weight: bold;
  line-height: 20px;
  position: absolute;
  top: -40px;
  z-index: 2147483647 !important;
}

body .introjs-arrow {
  content: "";
  border: 5px solid #003948;
  position: absolute;
}

body .introjs-arrow.top {
  border-color: #0000 #0000 #003948;
  top: -10px;
}

body .introjs-arrow.top-right {
  border-color: #0000 #0000 #003948;
  top: -10px;
  right: 10px;
}

body .introjs-arrow.top-middle {
  border-color: #0000 #0000 #003948;
  margin-left: -5px;
  top: -10px;
  left: 50%;
}

body .introjs-arrow.right {
  border-color: #0000 #0000 #0000 #003948;
  top: 10px;
  right: -10px;
}

body .introjs-arrow.bottom {
  border-color: #003948 #0000 #0000;
  bottom: -10px;
}

body .introjs-arrow.left {
  border-color: #0000 #003948 #0000 #0000;
  top: 10px;
  left: -10px;
}

body .introjs-tooltip {
  min-width: 300px;
  max-width: 300px;
  color: #fbfdfc;
  -o-transition: opacity .1s ease-out;
  text-align: center;
  background-color: #003948;
  padding: 30px 10px 10px;
  transition: opacity .1s ease-out;
  position: absolute;
}

body .introjs-tooltip-title {
  color: #0dd14d;
  font-size: 24px;
  font-weight: 500 !important;
}

body .introjs-tooltiptext {
  font-size: 20px;
}

body .introjs-tooltipbuttons {
  text-align: right;
  background: #003948;
  border-top: none;
  margin-left: -10px;
  margin-right: -10px;
  padding: 5px;
  position: relative;
  bottom: -44px;
}

body .introjs-button {
  text-shadow: none;
  white-space: nowrap;
  cursor: pointer;
  background-color: #0000;
  -webkit-background-clip: padding;
  -moz-background-clip: padding;
  -o-background-clip: padding-box;
  zoom: 1;
  border: none;
  outline: none;
  margin: 15px 0 0;
  padding: .8em 1.2em;
  font-family: sl_pixlregular;
  font-size: 20px;
  text-decoration: none;
  display: inline;
  position: relative;
  overflow: visible;
  box-shadow: none !important;
  color: #fff !important;
  outline: none !important;
}

body .introjs-button:hover {
  text-decoration: none;
}

body .introjs-button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

body .introjs-skipbutton {
  width: 25px;
  height: 25px;
  color: #003948;
  background-color: #003948;
  border: none;
  margin-right: 5px;
  line-height: 23px;
}

body .introjs-skipbutton:hover, body .introjs-skipbutton:focus, body .introjs-skipbutton:active {
  background-color: #00617b;
}

body .introjs-prevbutton {
  color: #fbfdfc;
  background-color: #0000;
  border: none;
  border-image: url("basic-button-dark.ea76d7c8.png") 18 fill / 18px;
  margin-bottom: 10px;
  margin-left: 10px;
}

body .introjs-prevbutton:hover, body .introjs-prevbutton:focus {
  border: none;
  border-image: url("basic-button-dark-hover.e4a225f2.png") 18 fill / 18px;
  background-color: #0000 !important;
}

body .introjs-prevbutton:active {
  border: none;
  border-image: url("basic-button-dark-active.983cd5a8.png") 18 fill / 18px;
  background-color: #0000 !important;
}

body .introjs-prevbutton.selected {
  border: none;
  border-image: url("basic-button-dark-active.983cd5a8.png") 18 fill / 18px;
  background-color: #0000 !important;
}

body .introjs-nextbutton {
  background-color: #0000;
  border: none;
  border-image: url("bright-green-button.8b74694f.png") 18 fill / 18px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #060a23 !important;
}

body .introjs-nextbutton:hover, body .introjs-nextbutton:focus {
  border: none;
  border-image: url("bright-green-button-hover.4f63ca34.png") 18 fill / 18px;
  background-color: #0000 !important;
}

body .introjs-nextbutton:active {
  border: none;
  border-image: url("bright-green-button-active.2b60c76b.png") 18 fill / 18px;
  background-color: #0000 !important;
}

body .introjs-nextbutton.selected {
  border: none;
  border-image: url("basic-button-alt-active.2d1d4423.png") 18 fill / 18px;
  background-color: #0000 !important;
}

body .introjs-disabled, body .introjs-disabled:hover, body .introjs-disabled:focus {
  color: #fbfdfc;
  box-shadow: none;
  cursor: default;
  background: none;
  border-color: #0000;
}

body .introjs-bullets {
  text-align: center;
  display: none;
}

body .introjs-bullets ul {
  clear: both;
  margin: 15px auto 0;
  padding: 0;
  display: inline-block;
}

body .introjs-bullets ul li {
  float: left;
  margin: 0 2px;
  list-style: none;
}

body .introjs-bullets ul li a {
  width: 8px;
  height: 8px;
  background: #ffffff1a;
  border-radius: 10px;
  text-decoration: none;
  display: block;
}

body .introjs-bullets ul li a:hover, body .introjs-bullets ul li a.active {
  background: #fff3;
}

body .introjsFloatingElement {
  height: 0;
  width: 0;
  position: absolute;
  top: 50%;
  left: 50%;
}

* {
  box-sizing: border-box;
}

html, body {
  width: 100%;
  height: 100%;
  background-color: #000;
  margin: 0;
  padding: 0;
  font-family: Avenir, Helvetica, sans-serif;
  font-size: 16px;
  overflow: hidden;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

button {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

#game {
  width: 100%;
  height: 100%;
  position: relative;
}

#main-canvas {
  width: 100%;
  height: 100%;
}

strong {
  color: #e9e5ad;
  font-weight: 600;
}

a {
  font-size: 25px;
}

a:link {
  color: #446ef6;
  text-decoration: none;
}

a:visited {
  color: #ccc;
}

a:hover, a:active {
  color: #fff;
}

::-moz-selection {
  color: #006c5b;
  background: #fbfdfc;
}

::selection {
  color: #006c5b;
  background: #fbfdfc;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #fffe8f;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #0f1853;
  border-radius: 20px;
}

input {
  caret-color: #006c5b;
}

/*# sourceMappingURL=index.57816744.css.map */
